import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '@styles/global'
import Section from '@components/Section'
import RichText from '@components/RichText'
import Image from '@components/Image'
import Breadcrumbs from '@components/Breadcrumbs'
import isArabic from '@utils/isArabic'
import { useRouter } from 'next/router'

const PageHeader = ({ className, content, page }) => {

	const textColor = () => {
		if (
			content?.background?.value === '#E1F9F7' 
			|| content?.background?.value === '#FFA688' 
			|| content?.background?.value === '#FFD29D'
			|| content?.background?.value === '#86DECF'
		) {
			return 'inherit'
		} else {
			return 'var(--beige20)'
		}
	}

	let headlineContainsArabic = isArabic(content?.headline)

	const router = useRouter()
	
	return (
		<Wrap 
			className={className}
			css={css`background-color: ${content?.background?.value};`}
			key={router?.asPath}
		>
			<HeaderBreadcrumbs color={textColor()} page={page} />
			<Column dir={headlineContainsArabic ? 'rtl' : 'ltr'} css={css`
				grid-column: ${content?.image ? '1/7' : '1/8'};
				align-self: ${content?.image ? 'center' : 'end'};
				${mobile}{
					grid-column: span 12;
				}
			`}>
				<Box css={css`
					background-color: ${content?.image ? 'var(--beige20)' : 'transparent'};
					padding: ${content?.image ? 'var(--l)' : '0px'};
					color: ${content?.image ? 'inherit' : textColor()};
				`}>
					<Heading 
						className='h1'
						css={css`
							${content?.image && `${desktop}{
								font-size: 34px;
								line-height: 37.4px;
							}`}
					`}>
						{content?.headline}
					</Heading>
					<Text className={!content?.image && 'para'}>
						<RichText content={content?.text} />
					</Text>
				</Box>
			</Column>
			{content?.image && 
				<HeaderImage 
					aspectRatio={1}
					image={content?.image} 
					fullHeight
				/>
			}
		</Wrap>
	)
}

const Wrap = styled(Section)`
	position: relative;
	padding: var(--l) 0;
	> div {
		min-height: 600px;
		max-width: 1400px;
	}
	${tablet}{
		> div {
			min-height: 430px;
		}
	}
	${mobile}{
		> div {
			min-height: 280px;
		}
	}
`
const HeaderBreadcrumbs = styled(Breadcrumbs)`
	position: absolute;
	z-index: 1;
	left: var(--s);
	top: 0;
	display: flex;
	align-items: center;
	height: 70px;
	${mobile}{
		height: 32px;
	}
`
const Column = styled.div`
	grid-row: 1;
	z-index: 1;
`
const Box = styled.div`
	margin: calc(var(--m) * 2) 0;
	${mobile}{
		margin: 0 var(--xs);
	}
`
const Heading = styled.h1`
	grid-column: span 12;
	margin-bottom: var(--m);
`
const Text = styled.div`
	
`
const HeaderImage = styled(Image)`
	grid-column: 6/13;
	grid-row: 1;
	margin: var(--m) 0;
	background: rgba(0, 0, 0, 0.05);
	> div {
		object-position: center;
	}
	${mobile}{
		grid-column: span 12;
		grid-row: 2;
		margin: calc(var(--m) * -1) 0 0 0;
	}
`
PageHeader.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default PageHeader